<template>
  <b-card class="mx-auto mt-3 main-form">
    <b-card-header>
      <b-row>
        <b-col><h5>Seleccioná el Monto a pagar (ARS):</h5></b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="pt-3">
      <!-- <b-row class="justify-content-center">
        <b-col xs="12" sm="8" md="8" class="max-login-width"> -->
          <b-tabs class="dietrich" pills fill align="center">
            <b-tab title="Menor a 200,000 ARS">
              <!-- <b-row class="form mt-3">
                <b-col> -->
                  <b-row class="mt-3">
                    <b-col>
                      <div class="text-center">
                        <h5>
                          Seleccioná el criptoactivo y el procesador de pago con
                          el que deseas pagar
                        </h5>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col class="mb-2">Criptoactivo:</b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-dropdown
                        variant="combo-bithan"
                        id="dropdown-1"
                        :text="activoSeleccionado.name"
                      >
                        <template #button-content>
                          <img
                            :src="getActivoImage(activoSeleccionado.icon)"
                            :alt="activoSeleccionado.name"
                            class="mr-1"
                          />
                          {{ activoSeleccionado.name }}
                        </template>
                        <b-dropdown-item
                          v-for="activo in activos"
                          :key="activo.name"
                          @click="() => (activoSeleccionado = activo)"
                        >
                          <img
                            :src="getActivoImage(activo.icon)"
                            :alt="activo.name"
                            class="mr-1"
                          />
                          {{ activo.name }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                <!-- </b-col>
              </b-row> -->
              <b-row class="mt-4">
                <b-col>
                  <b-row class="mb-2">
                    <b-col>
                      Procesadores de pago
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="pl-3" v-for="(procesador, i) in procesadores" :key="i">
                      <label class="radio">
                        <input
                          type="radio"
                          name="processor"
                          class="processor"
                          :checked="procesador.selected"
                          :disabled="procesador.disabled"
                          :value="i + 1"/>
                        <span
                          ><img
                            :src="getProcesadorImage(procesador.image)"
                            :alt="procesador.name"
                          /> </span
                      ></label>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
              <hr class="mt-4" />
              <b-row class="text-center mt-4">
                <b-col>
                  <a
                    :href="bithanLogin"
                  >
                    <b-button
                      variant="primary"
                      class="btn align-items-center btn-primary btn-lg mr-2"
                      >Realizar Pago</b-button
                    >
                  </a>
                  <b-button
                    class="btn btn-link align-items-center btn-lg cancel"
                    >Cancelar</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="mb-1 mt-2">
                <b-col>
                  <small
                    >Btcpay acepta como medios de pago Bitcoin, Lighning y
                    Liquid Tether además de ofrecerte costos bajos en
                    comisiones. Para mas información, visitá el
                    <a href="#">FAQ</a> de la empresa.</small
                  >
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Mayor a 200,000 ARS">
              <b-row class="form mt-3">
                <b-col>
                  <div class="text-center mb-4">
                    <h5>
                      Por favor, para proceder ingresá la documentación
                      requerida que se te solicitará a continuación
                    </h5>
                  </div>
                </b-col>
              </b-row>
              <hr class="mt-4" />
              <b-row class="text-center mt-4">
                <b-col>
                  <b-button
                    variant="primary"
                    class="btn align-items-center btn-primary btn-lg mr-2"
                    @click="goToSignUp()"
                    >Proceder</b-button
                  >
                  <b-button
                    class="btn btn-link align-items-center btn-lg cancel"
                    >Cancelar</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="mb-2 mt-2">
                <b-col>
                  <small
                    >Al elegir esta opción el comercio te solicitará la
                    documentación necesaria para realizar tu compra. Ante
                    cualquier duda visitá el <a href="#">FAQ</a> de la
                    empresa.</small
                  >
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        <!-- </b-col>
      </b-row> -->
    </b-card-body>
    <b-card-footer class="text-right">
      <small class="align-text-bottom"
        >powered by
        <a href="#"
          ><img src="@/assets/img/bithan-logo.svg" alt="Bithan" height="14"/></a
      ></small>
    </b-card-footer>
  </b-card>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue"
import constants from "@/constants"
class Cryptoactivo {
  constructor(name, icon) {
    this.name = name
    this.icon = icon
  }
}

class ProcesadorPago {
  constructor(name, image, disabled = true, selected = false) {
    this.name = name
    this.image = image
    this.disabled = disabled
    this.selected = selected
  }
}
export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      montoMenor: true,
      activoSeleccionado: null,
      activos: [
        new Cryptoactivo("BTC", "btc.svg"),
        new Cryptoactivo("BTC.Lightning", "lightning.svg"),
        new Cryptoactivo("USDT.Liquid", "usdt.svg"),
      ],
      procesadores: [
        new ProcesadorPago("Btcpay", "btcpay.svg", false, true),
        new ProcesadorPago("Bitpay", "bitpay.svg"),
        new ProcesadorPago("Bitfinex", "bitfinex.png"),
        new ProcesadorPago("Solana", "solana.svg")
      ],
      bithanLogin: constants.BITHAN_LOGIN
    }
  },
  created() {
    this.activoSeleccionado = this.activos[0]
  },
  methods: {
    getProcesadorImage(image) {
      return require(`@/assets/img/procs/${image}`)
    },
    getActivoImage(image) {
      return require(`@/assets/img/tokens/${image}`)
    },
    goToSignUp() {
      this.$router.push("/dietrich/signup")
    }
  },
  computed: {}
}
</script>

<style lang="css" scoped>
body {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #eee;
}
.main-form {
  width: 900px;
}
.max-login-width {
  max-width: 900px;
}

label.radio {
  cursor: pointer;
  margin-right: 12px;
}

label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}

label.radio span {
  padding: 7px 12px;
  border: 1px solid #d5d5d5;
  display: inline-block;
  color: #005d7b;
  border-radius: 6px;
  text-transform: capitalize;
}

label.radio input:checked + span {
  border-color: #005d7b;
  background-color: rgb(0 93 123 / 15%);
  color: #fff;
}

label.radio input:disabled + span {
  opacity: 0.3;
}
button.cancel {
  color: #005d7b;
}
.btn-secondary {
  background-color: #fff;
  border: none;
}
.processor:disabled{
  cursor: default;
  pointer-events: all !important;
}
.card-header{
  background-color: transparent;
}
.card-footer{
  background-color: transparent;
}
</style>
